






















import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import CloseCircleIcon from '@/app/ui/assets/close_circle_icon_modal.vue'

@Component({
  components: {
    Modal,
    Button,
    CloseCircleIcon,
  },
})

export default class ModalFailed extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: "Title" }) private title!: string
  @Prop({ default: "Description" }) private description!: string

  private hasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }
}
