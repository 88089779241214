



























































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import WarningIcon from '@/app/ui/assets/icon_warning_circle.vue'
import SuccessIcon from '@/app/ui/assets/success_icon_alt.vue'
import DownloadIcon from '@/app/ui/assets/icon_download_filled.vue'
import BackIcon from '@/app/ui/assets/icon_back.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_data-paket.vue'
import IconWaitingForApproval from '@/app/ui/assets/clock.vue'
import IconApprove from '@/app/ui/assets/icon_check_circle_thin.vue'
import IconReject from '@/app/ui/assets/close_circle.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import ModalFailed from '../../components/Modals/ModalFailed/index.vue'
import IconModalReject from '@/app/ui/assets/icon_check_circle.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  IDataCell,
  IHeaderCell,
  IOptions,
} from '@/app/infrastructures/misc/Constants/adjustSaldoApproval'
import {
  enumStatus,
  EnumAlertMessage,
} from '@/app/infrastructures/misc/Constants/manualAdjustBasicFee'
import {
  ManualAdjustBasicFeeHistoryDetailData,
  ManualAdjustBasicFeeHistoryDetailDataUpload,
} from '@/domain/entities/Payroll'
import IconCheckCircle from '@/app/ui/assets/ics_o_check_circle.vue'
import PayrollController from '@/app/ui/controllers/PayrollController'
import { Validations } from 'vuelidate-property-decorators'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { SubmitApprovalManualAdjustBasicFeeRequest } from '@/data/payload/api/PayrollRequest'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import ModalNotes from '../../components/Modals/ModalNotes/index.vue'

@Component({
  components: {
    Button,
    DataTableV2,
    PaginationNav,
    WarningIcon,
    SuccessIcon,
    DownloadIcon,
    BackIcon,
    EmptyIllustration,
    IconApprove,
    IconWaitingForApproval,
    IconReject,
    ModalSuccess,
    Modal,
    TextInput,
    ModalFailed,
    IconModalReject,
    LoadingOverlay,
    IconCheckCircle,
    ModalConfirm,
    ModalNotes,
  },
})
export default class ManualAdjustBasicFeeUploadBulkyPage extends Vue {
  controller = PayrollController

  enumAlertMessage = EnumAlertMessage
  adjustBasicFeeStatus = enumStatus
  filename = ''
  reasonRejected = ''
  reason = ''
  showModalSuccess = false
  showModalConfirmation = false
  showApproveModalConfirmation = false
  showModalFailed = false
  status = ''
  showModalFailedConnection = false
  showModalNotes = false
  createdAt: string | Date = ''
  updatedAt: string | Date = ''
  updatedBy = ''
  createdBy = ''
  data: Array<Array<string | number | IDataCell>> = []
  rows = {
    rowValid: 0,
    rowInvalid: 0,
  }
  courier: Record<string, number> = {
    courierValid: 0,
    courierInvalid: 0,
  }
  amountValid: Record<string, number> = {
    addition: 0,
    reduction: 0,
  }
  amountInvalid: Record<string, number> = {
    addition: 0,
    reduction: 0,
  }
  paginationOptions: Array<IOptions> = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]
  parameters = {
    page: 1,
    per_page: 10,
  }
  headers: Array<string | IHeaderCell> = [
    this.headerCellMapper('Baris', '80px'),
    this.headerCellMapper('ID Kurir', '120px'),
    this.headerCellMapper('Nomor STT', '180px'),
    this.headerCellMapper('Tipe Pengiriman', '180px'),
    this.headerCellMapper('Jumlah', '170px'),
    this.headerCellMapper('Catatan', '430px'),
    this.headerCellMapper('Status', ''),
  ]

  created(): void {
    this.getApprovalAdjustBasicFeeDetail()
  }

  mounted(): void {
    this.onSetDisplayBreadcrumb(false)
    window.addEventListener('offline', this.onHandleOffline)
  }

  @Validations()
  validations() {
    return {
      reason: {
        required,
        maxLength: maxLength(90),
        minLength: minLength(10),
      },
    }
  }

  get params(): Record<string, string | number> {
    return {
      ...this.parameters,
      id: parseInt(this.$route.params.historyId),
    }
  }

  get showApproveRejectButton(): boolean {
    return this.$route.name === 'AdjustBasicFeeApprovalDetail'
  }

  get backButtonText(): string {
    if (this.$route.name === 'AdjustBasicFeeApprovalDetail') {
      return 'Kembali'
    }
    return 'Kembali ke Adjust Basic Fee'
  }

  private getApprovalAdjustBasicFeeDetail(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getHistoryManualAdjustBasicFee(this.params)
  }

  public onChangePaginationOption(perPageAmount: number): void {
    this.parameters.per_page = perPageAmount
    this.getApprovalAdjustBasicFeeDetail(true)
  }

  public onClickReject() {
    this.showModalConfirmation = false
    const payload = new SubmitApprovalManualAdjustBasicFeeRequest(
      <number>this.params.id,
      'REJECTED',
      this.reason
    )

    this.controller.submitApprovalManualAdjustBasicFee(payload)
  }

  public onClickApprove() {
    this.showApproveModalConfirmation = false
    const payload = new SubmitApprovalManualAdjustBasicFeeRequest(
      <number>this.params.id,
      'APPROVED'
    )

    this.controller.submitApprovalManualAdjustBasicFee(payload)
  }

  private tableCellMapper(
    value: string | number | boolean,
    colWidth: string
  ): IDataCell {
    return {
      value: value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private formatAmount(amount?: number): string {
    if (amount) {
      if (amount < 0) {
        return `-${Utils.toRupiah(amount * -1)}`
      } else if (amount >= 0) {
        return `+${Utils.toRupiah(amount)}`
      }
    }

    return '0'
  }

  private coloringStatus(status: string): string {
    let result = ''
    switch (status) {
      case this.adjustBasicFeeStatus.APPROVED:
        result = 'bg-green-1 text-green-2'
        break
      case this.adjustBasicFeeStatus.REJECTED:
        result = 'bg-red-5 text-red-1'
        break
      case this.adjustBasicFeeStatus.WAITING:
        result = 'bg-orange-100 text-orange-2'
        break
    }
    return result
  }

  private formatedStatus(status: string): string {
    let result = ''
    switch (status) {
      case this.adjustBasicFeeStatus.APPROVED:
        result = 'Terima'
        break
      case this.adjustBasicFeeStatus.REJECTED:
        result = 'Tolak'
        break
      case this.adjustBasicFeeStatus.WAITING:
        result = 'Menunggu'
        break
    }
    return result
  }

  private formatedStatusSummary(status: string): string {
    let result = ''
    switch (status) {
      case this.adjustBasicFeeStatus.APPROVED:
        result =
          'Permintaan adjust basic fee telah diterima oleh' +
          ` <span class="font-bold">${this.updatedBy || '-'}</span>` +
          ` tanggal <span class="font-bold">${
            this.updatedAt
              ? Utils.formatDateWithIDLocale(
                  <string>this.updatedAt,
                  'dddd D MMMM YYYY'
                )
              : '-'
          }</span>` +
          ` pukul <span class="font-bold">${
            this.updatedAt
              ? Utils.formatDateWithIDLocale(<string>this.updatedAt, 'HH:mm')
              : '-'
          }</span>`
        break
      case this.adjustBasicFeeStatus.REJECTED:
        result =
          'Permintaan adjust basic fee telah ditolak oleh' +
          ` <span class="font-bold">${this.updatedBy || '-'}</span>` +
          ` tanggal <span class="font-bold">${
            this.updatedAt
              ? Utils.formatDateWithIDLocale(
                  <string>this.updatedAt,
                  'dddd D MMMM YYYY'
                )
              : '-'
          }</span>` +
          ` pukul <span class="font-bold">${
            this.updatedAt
              ? Utils.formatDateWithIDLocale(<string>this.updatedAt, 'HH:mm')
              : '-'
          }</span>`
        break
      case this.adjustBasicFeeStatus.WAITING:
        result =
          'Permintaan adjust basic fee telah dikirim oleh' +
          ` <span class="font-bold">${this.createdBy || '-'}</span>` +
          ` tanggal <span class="font-bold">${
            this.createdAt
              ? Utils.formatDateWithIDLocale(
                  <string>this.createdAt,
                  'dddd D MMMM YYYY'
                )
              : '-'
          }</span>` +
          ` pukul <span class="font-bold">${
            this.createdAt
              ? Utils.formatDateWithIDLocale(<string>this.createdAt, 'HH:mm')
              : '-'
          }</span>`
        break
    }
    return result
  }

  private onSetDisplayBreadcrumb(isDisplayed: boolean): void {
    const breadcrumb = document.getElementById('breadcrumb')
    if (breadcrumb) {
      if (isDisplayed) {
        breadcrumb.style.display = 'flex'
      } else {
        breadcrumb.style.display = 'none'
      }
    }
  }

  private onHandleOffline(): void {
    this.showModalConfirmation = false
    this.showModalFailedConnection = true
  }

  public onDownloadTemplate(): void {
    const source =
      this.$route.name === 'AdjustBasicFeeApprovalDetail'
        ? 'filepathData'
        : 'filepath'
    const linkSourceFile = this.controller
      .dataHistoryManualAdjustBasicFeeDetail[source]
    const filepath = this.controller.dataHistoryManualAdjustBasicFeeDetail
      .filepath
    if (filepath) window.open(linkSourceFile || filepath)
  }

  private formattedTaskType(taskType: string): string {
    if (!taskType) {
      return '-'
    }
    if (taskType === 'DELIVERY') {
      return 'Delivery'
    }

    return 'Pick Up'
  }

  @Watch('controller.dataHistoryManualAdjustBasicFeeDetail')
  onWatchDataDetail(value: ManualAdjustBasicFeeHistoryDetailData): void {
    const summary = value.summary
    if (!summary) return

    const dataUpload = value.dataUpload
    if (!dataUpload) return

    this.filename = value.filename || ''
    this.createdAt = value.createdAt || ''
    this.createdBy = value.createdBy || ''
    this.updatedAt = value.updatedAt || ''
    this.updatedBy = value.updatedBy || ''
    this.status = value.status || ''
    this.courier.courierInvalid = summary.totalCourierInvalid || 0
    this.courier.courierValid = summary.totalCourierValid || 0
    this.rows.rowValid = summary.totalRowValid || 0
    this.rows.rowInvalid = summary.totalRowInvalid || 0
    this.amountValid.addition = summary.totalAmountValid?.addition || 0
    this.amountValid.reduction = summary.totalAmountValid?.reduction || 0
    this.amountInvalid.addition = summary.totalAmountInvalid?.addition || 0
    this.amountInvalid.reduction = summary.totalAmountInvalid?.reduction || 0
    this.reasonRejected = value.reason || ''

    this.data =
      dataUpload.map(
        (item: ManualAdjustBasicFeeHistoryDetailDataUpload, index: number) => {
          return [
            this.tableCellMapper(
              (this.parameters.page - 1) * this.parameters.per_page + index + 1,
              '80px'
            ),
            this.tableCellMapper(String(item.courierId), '120px'),
            this.tableCellMapper(<string>item.sttNumber, '180px'),
            this.tableCellMapper(
              this.formattedTaskType(<string>item.deliveryType?.toUpperCase()),
              '180px'
            ),
            this.tableCellMapper(
              item.amount ? this.formatAmount(Number(item.amount)) : 0,
              '170px'
            ),
            this.tableCellMapper(item.note || '', '430px'),
            this.tableCellMapper(item.status || '', ''),
          ]
        }
      ) || []
  }

  @Watch('controller.isApproveAdjustBasicFeeSuccess')
  onIsApproveAdjustBasicFeeSuccessChanged(data: boolean) {
    if (data) {
      this.showModalSuccess = true
      this.controller.setIsApproveAdjustBasicFeeSuccess(false)
    }
  }

  @Watch('controller.isRejectAdjustBasicFeeSuccess')
  onIsRejectAdjustBasicFeeSuccessChanged(data: boolean) {
    if (data) {
      this.showModalFailed = true
      this.reason = ''
      this.controller.setIsRejectAdjustBasicFeeSuccess(false)
    }
  }

  beforeDestroy(): void {
    this.onSetDisplayBreadcrumb(true)
    window.removeEventListener('offline', this.onHandleOffline)
  }
}
