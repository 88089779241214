function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col gap-6 text-sm mt-6"},[(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),_c('div',{staticClass:"flex justify-between items-center"},[_c('router-link',{staticClass:"button-back",attrs:{"to":".."}},[_c('BackIcon'),_c('span',[_vm._v(_vm._s(_vm.backButtonText))])],1),_c('div',{staticClass:"flex gap-2"},[_c('Button',{attrs:{"buttonStyle":"outline","disabled":!_vm.status ? false : _vm.data.length === 0},on:{"click":function () {}}},[_c('div',{staticClass:"flex gap-2.5",on:{"click":_vm.onDownloadTemplate}},[_c('div',{staticClass:"m-auto"},[_c('DownloadIcon')],1),_c('span',[_vm._v("Download Excel")])])]),(_vm.showApproveRejectButton)?[(_vm.status === _vm.adjustBasicFeeStatus.WAITING)?_c('Button',{on:{"click":function () { return (_vm.showModalConfirmation = true); }}},[_c('span',[_vm._v("Tolak")])]):_vm._e(),(_vm.status === _vm.adjustBasicFeeStatus.WAITING)?_c('Button',{attrs:{"buttonStyle":"green"},on:{"click":function () {
              _vm.showApproveModalConfirmation = true
            }}},[_c('span',[_vm._v("Terima")])]):_vm._e()]:_vm._e()],2)],1),(_vm.status)?_c('div',{staticClass:"p-5 rounded-md border border-gray-5",staticStyle:{"box-shadow":"0px 4px 8px rgba(26, 20, 33, 0.15)"}},[_c('div',{staticClass:"flex gap-4"},[(_vm.status === _vm.adjustBasicFeeStatus.APPROVED)?_c('IconApprove',{staticClass:"-mt-0.5",attrs:{"width":"25","height":"24"}}):_vm._e(),(_vm.status === _vm.adjustBasicFeeStatus.REJECTED)?_c('IconReject',{staticClass:"-mt-0.5",attrs:{"width":"25","height":"24"}}):_vm._e(),(_vm.status === _vm.adjustBasicFeeStatus.WAITING)?_c('IconWaitingForApproval',{staticClass:"-mt-0.5",attrs:{"width":"25","height":"24"}}):_vm._e(),_c('div',[_c('span',{staticClass:"font-semibold py-1 px-6 rounded-2xl",class:_vm.coloringStatus(_vm.status)},[_vm._v(_vm._s(_vm.formatedStatus(_vm.status)))])])],1),_c('div',{staticClass:"pt-2.5 px-3 mx-8 text-gray-4",domProps:{"innerHTML":_vm._s(_vm.formatedStatusSummary(_vm.status))}}),(_vm.status === _vm.adjustBasicFeeStatus.REJECTED)?_c('div',{staticClass:"bg-gray-3 py-2 px-3 text-gray-4 font-bold rounded-lg mt-2 mx-11 break-words"},[_c('p',[_vm._v("Alasan: "+_vm._s(_vm.reasonRejected || '-'))])]):_vm._e()]):_vm._e(),_c('div',[_c('div',{staticClass:"top-summary"},[_c('div',{staticClass:"top-summary-section border-r-0 p-4"},[_c('div',{staticClass:"top-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Nama File:")]),_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"overflow-hidden overflow-ellipsis",attrs:{"title":_vm.filename}},[_vm._v(_vm._s(_vm.filename))]),_c('button',{staticClass:"text-2xs text-bgPrimary self-start",on:{"click":function () {
                  _vm.showModalNotes = true
                }}},[_vm._v(" Lihat Detail ")])])])]),_c('div',{staticClass:"top-summary-section p-4"},[_c('div',{staticClass:"grid grid-cols-2"},[_c('div',{staticClass:"top-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Total Baris Valid:")]),_c('span',[_vm._v(_vm._s(_vm.rows.rowValid))])]),_c('div',{staticClass:"top-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Total Baris Tidak Valid:")]),_c('span',[_vm._v(_vm._s(_vm.rows.rowInvalid))])])])]),_c('div',{staticClass:"top-summary-section border-l-0 p-4"},[_c('div',{staticClass:"grid grid-cols-2"},[_c('div',{staticClass:"top-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Total Kurir Valid:")]),_c('span',[_vm._v(_vm._s(_vm.courier.courierValid))])]),_c('div',{staticClass:"top-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Total Kurir Tidak Valid:")]),_c('span',[_vm._v(_vm._s(_vm.courier.courierInvalid))])])])])]),_c('div',{staticClass:"bottom-summary"},[_c('div',{staticClass:"bottom-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Total Saldo Valid:")]),_c('span',{staticClass:"total-addition"},[_vm._v("Penambahan: "+_vm._s(_vm.formatAmount(_vm.amountValid.addition))+" ")]),_c('span',{staticClass:"total-substraction"},[_vm._v("Pengurangan: "+_vm._s(_vm.formatAmount(_vm.amountValid.reduction)))])]),_c('div',{staticClass:"bottom-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Total Saldo Tidak Valid:")]),_c('span',{staticClass:"total-addition"},[_vm._v("Penambahan: "+_vm._s(_vm.formatAmount(_vm.amountInvalid.addition))+" ")]),_c('span',{staticClass:"total-substraction"},[_vm._v("Pengurangan: "+_vm._s(_vm.formatAmount(_vm.amountInvalid.reduction)))])])])]),_c('div',[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.data},scopedSlots:_vm._u([{key:"0",fn:function(ref){
                var rest = objectWithoutProperties( ref, [] );
                var props = rest;
return [_c('span',{staticClass:"w-10 text-center mr-4 text-secondaryText"},[_vm._v(_vm._s(props.data.value))])]}},{key:"1",fn:function(ref){
                var rest = objectWithoutProperties( ref, [] );
                var props = rest;
return [_c('span',{staticClass:"text-sm text-secondaryText"},[_vm._v(_vm._s(props.data.value))])]}},{key:"2",fn:function(ref){
                var rest = objectWithoutProperties( ref, [] );
                var props = rest;
return [_c('span',{staticClass:"text-sm text-secondaryText"},[_vm._v(_vm._s(props.data.value))])]}},{key:"4",fn:function(ref){
                var rest = objectWithoutProperties( ref, [] );
                var props = rest;
return [_c('span',{class:[
            'font-semibold mr-6',
            String(props.data.value).includes('+')
              ? 'text-green-2'
              : 'text-red-4' ]},[_vm._v(_vm._s(props.data.value))])]}},{key:"3",fn:function(ref){
          var rest = objectWithoutProperties( ref, [] );
          var props = rest;
return [_c('div',{staticClass:"mr-6 text-sm text-secondaryText"},[_c('span',[_vm._v(_vm._s(props.data.value))])])]}},{key:"5",fn:function(ref){
          var rest = objectWithoutProperties( ref, [] );
          var props = rest;
return [_c('span',{staticClass:"text-sm text-secondaryText"},[_vm._v(_vm._s(props.data.value))])]}},{key:"6",fn:function(ref){
          var rest = objectWithoutProperties( ref, [] );
          var props = rest;
return [_c('span',{class:[
            String(props.data.value).toUpperCase() === 'VALID'
              ? 'text-green-2'
              : 'text-red-4' ]},[_vm._v(_vm._s(props.data.value.toUpperCase() === 'VALID' ? props.data.value.toUpperCase() : props.data.value))])]}},{key:"data-empty",fn:function(){return [(_vm.status)?_c('div',{staticClass:"flex flex-col w-full h-96 gap-4 justify-center items-center"},[_c('EmptyIllustration'),_c('span',{staticClass:"font-semibold"},[_vm._v("Belum Ada Data yang di Upload")]),_c('span',{staticClass:"text-gray-1"},[_vm._v("Silakan upload file excel untuk melakukan adjust basic fee kurir")]),_c('Button',{on:{"click":function () {}}},[_c('span',[_vm._v("Upload Excel")])])],1):_vm._e()]},proxy:true}])})],1),_c('PaginationNav',{staticClass:"mb-8",attrs:{"lang":"id","page":_vm.parameters.page,"perPage":_vm.parameters.per_page,"enablePerPageOption":true,"perPageOptions":_vm.paginationOptions,"totalItem":_vm.controller.paginationData.totalItem},on:{"onChangeOption":function (e) { return _vm.onChangePaginationOption(e); },"input":function () {
        _vm.getApprovalAdjustBasicFeeDetail()
      }},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}),_c('ModalSuccess',{attrs:{"visible":_vm.showModalSuccess,"title":"Data Adjustment Berhasil Diterima","textButton":"Oke","description":""},on:{"close":function($event){return _vm.$router.back()}}}),_c('Modal',{attrs:{"visible":_vm.showModalConfirmation},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"font-semibold text-sm text-bgMatterhorn"},[_vm._v(" Konfirmasi Tolak File ")]),_c('div',{staticClass:"mt-2 text-xs text-bgMatterhorn"},[_vm._v(" Masukkan alasan penolakan anda ")])]},proxy:true},{key:"modal-body",fn:function(){return [_c('div',{staticClass:"text-left"},[_c('TextInput',{attrs:{"type":"textarea","isTextArea":"","customClass":"hide-button-clear text-xs","placeholder":"Masukkan alasan penolakan Anda"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('span',{staticClass:"text-gray-1 mt-2 text-xs"},[_vm._v("Alasan minimal 10 & maksimal 90 karakter")])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"flex gap-3"},[_c('Button',{staticClass:"py-3 px-14",attrs:{"customPadding":true,"buttonStyle":"outline"},on:{"click":function () { return (_vm.showModalConfirmation = false); }}},[_c('span',[_vm._v("Cek Ulang")])]),_c('Button',{staticClass:"py-3 px-18",attrs:{"customPadding":true,"disabled":_vm.$v.reason.$invalid},on:{"click":_vm.onClickReject}},[_c('span',[_vm._v("Tolak")])])],1)]},proxy:true}])}),_c('div',{staticClass:"approve-confirmation"},[_c('ModalConfirm',{attrs:{"visible":_vm.showApproveModalConfirmation,"title":"Terima data adjustment?","description":"Pastikan data yang diupload udah sesuai ya","actionBtnLabel":"Terima"},on:{"action":_vm.onClickApprove,"cancel":function () {
          _vm.showApproveModalConfirmation = false
        }}})],1),_c('ModalFailed',{attrs:{"visible":_vm.showModalFailed,"title":"Data Adjustment Telah Ditolak","textButton":"Oke","description":"","customIcon":true},on:{"close":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"modal-icon",fn:function(){return [_c('IconModalReject',{attrs:{"secondFill":"#FEE8E8","fill":"#E40101"}})]},proxy:true}])}),_c('ModalFailed',{attrs:{"visible":_vm.showModalFailedConnection,"title":"Koneksi Internet Bermasalah","description":"Klik Muat Ulang untuk memperbarui halaman."}},[_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{on:{"click":function($event){return _vm.$router.go(0)}}},[_c('span',[_vm._v("Muat Ulang")])])],1)]),_c('ModalNotes',{attrs:{"visible":_vm.showModalNotes,"title":"Nama File","notes":_vm.filename},on:{"close":function () {
        _vm.showModalNotes = false
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }